import React, { useEffect, useState } from 'react';

const PageModal = ({ line, onClose, onSave, onDelete }) => {
    const [name, setName] = useState('');

    useEffect(() => {
        setName(line?.name || '');
    }, [line]);

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleDelete = () => {
        if (name) {
            onDelete(name);
            onClose();
        } else {
            console.error('Error: Page name is required for deletion.');
        }
    };

    const handleSave = () => {
        if (!name) {
            console.error('Error: Name is required.');
            return;
        }

        fetch('https://1qr33vjc86.execute-api.us-west-1.amazonaws.com/Staging/page', {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                updates: { name }
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Saved', data);
            onSave({ ...line, name });
            onClose();
        })
        .catch(error => {
            console.error('Error during save:', error);
            alert('Failed to save changes. The page may have been modified elsewhere.');
        });
    };

    const isURL = (value) => {
        try {
            new URL(value);
            return true;
        } catch (e) {
            return false;
        }
    };

    const dynamoDBConsoleURL = (name) => {
        return `https://us-west-1.console.aws.amazon.com/dynamodbv2/home?region=us-west-1#edit-item?itemMode=2&pk=${encodeURIComponent(name)}&route=ROUTE_ITEM_EXPLORER&sk=&table=phone_line_assembly`;
    };

    const renderValue = (key, value) => {
        if (!value) return '-';
        
        // Handle URL fields
        if (isURL(value)) {
            return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>;
        }

        // Handle community and article slugs
        if (key === 'communitySlug' && value) {
            const communityURL = `https://claimyr.com/${value}`;
            return <a href={communityURL} target="_blank" rel="noopener noreferrer">{value}</a>;
        }

        if (key === 'articleSlug' && value) {
            const articleURL = `https://claimyr.com/articles/${value}`;
            return <a href={articleURL} target="_blank" rel="noopener noreferrer">{value}</a>;
        }

        // Handle phone numbers
        if (key === 'agencyPhoneNumber' && value) {
            return <a href={`tel:${value}`}>{value}</a>;
        }

        return value;
    };

    if (!line) return null;

    return (
        <div className="page-modal-backdrop" onClick={onClose}>
            <div className="page-modal" onClick={e => e.stopPropagation()}>
                <div className="page-modal-header">
                    <h2>Edit Page</h2>
                    <div className="page-modal-actions">
                        <button onClick={handleDelete}>Delete</button>
                        <button onClick={handleSave}>Save</button>
                        <button onClick={onClose}>Close</button>
                    </div>
                </div>
                <div className="page-modal-content">
                    <table className="page-attributes-table">
                        <thead>
                            <tr>
                                <th>Attribute</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(line).map(([key, value]) => (
                                <tr key={key}>
                                    <td>{key}:</td>
                                    <td>{renderValue(key, value)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="page-modal-footer">
                        <a 
                            href={dynamoDBConsoleURL(name)} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="dynamo-link"
                        >
                            View in DynamoDB Console
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageModal;